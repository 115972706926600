<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="breadcrumb">
      <h1>Importar Pacientes</h1>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6">
                <label class="btn bg-custom-green rounded col-form-label mr-2">
                  <input type="file" hidden
                   id="file"
                   ref="file"
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                    application/vnd.ms-excel"
                   @change="handleFileUpload()">
                  Seleccionar Archivo
                </label>
                <button class="btn bg-custom-green rounded mr-2"
                 v-if="existeArchivo"
                 @click="subirArchivo">
                 Subir
                </button>
                <router-link :to="{ name: 'paciente-index' }">
                  <button class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="isUploading">
            <div class="text-center text-danger my-2">
              <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
              <strong>Cargando...</strong>
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="row mb-3">
              <div class="col-sm-6">
                <b-dropdown id="dropdown-dropright"
                  dropright text="Descargar plantilla"
                  variant="secondary"
                  class="m-2">
                  <b-dropdown-item href="#"
                    @click="downloadPlantillaNewDatos">
                    Nuevos datos
                  </b-dropdown-item>
                </b-dropdown>
                <button class="btn btn-success ml-3" v-if="pacientes.length > 0"
                  @click="guardarListaConfirmada">
                  Guardar
                </button>
              </div>
              <div class="col-sm-6 pt-3">
                <div v-if="pacientes.length > 0">
                  Total Filas: <span class="font-weight-bold">{{ pacientes.length }}</span>
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="pacientes.length > 0">
              <div class="col-sm-12">
                <b-table class="table table-striped table-hover"
                  :fields="fieldsPacientes"
                  :items="tablePacientes"
                  :per-page="perPagePacientes">
                  <template #cell(eliminar)="items">
                    <i class="nav-icon i-Close-Window font-weight-bold text-danger"
                      @click="eliminarItem(items.index)"
                      style="cursor: pointer"></i>
                  </template>
                </b-table>
              </div>
              <div class="col-sm-4 offset-sm-8">
                <b-pagination
                  v-model="currentPagePacientes"
                  :total-rows="pacientes.length"
                  :per-page="perPagePacientes"
                  @click.native="listarTablaPacientes"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
            <div class="row mb-2" v-if="pacientes.length === 0">
              <div class="col-sm-12">
                <div class="alert alert-info" role="alert">
                  <div class="text-center">
                    <p><strong>¡IMPORTANTE!</strong></p>
                    Al importar mantener los titulos y las columnas
                    en sus respectivas ubicaciones.
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" v-if="listaValidaciones.length > 0">
              <div class="col-sm-5 offset-sm-4">
                <b-table class="table table-striped table-hover"
                  :fields="fieldsValidaciones"
                  :items="tableValidaciones"
                  :per-page="perPageValidaciones">
                </b-table>
              </div>
              <div class="col-sm-4 offset-sm-8">
                <b-pagination
                  v-model="currentPageValidacion"
                  :total-rows="listaValidaciones.length"
                  :per-page="perPageValidaciones"
                  @click.native="listarTablaValidacion"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
            <div class="row mb-4" v-if="pacientes.length === 0 && listaValidaciones.length === 0">
              <div class="col-sm-3"></div>
              <div class="col-sm-3 table-scroll px-0">
                <b-table class="table table-striped table-hover"
                :fields="fieldsPais" :items="listaPaises">
                  <template #thead-top>
                    <tr>
                      <th colspan="2" class="py-1 text-center">Lista de Paises</th>
                    </tr>
                  </template>
                </b-table>
              </div>
              <div class="col-sm-4">
                <b-table class="table table-striped table-hover"
                :fields="fieldsGenero" :items="listaGeneros">
                  <template #thead-top>
                    <tr>
                      <th colspan="3" class="py-1 text-center">Generos</th>
                    </tr>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'import',
  data() {
    return {
      isLoading: true,
      isUploading: false,
      existeArchivo: false,
      file: null,
      fieldsValidaciones: [
        {
          key: 'fila', label: 'Fila', thClass: 'bg-danger', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'campo', label: 'Campo', thClass: 'bg-danger', class: 'text-center', thStyle: { width: '35%' }, sortable: true,
        },
        {
          key: 'error', label: 'Error', thClass: 'bg-danger', class: 'text-center', thStyle: { width: '30%' }, sortable: true,
        },
        {
          key: 'valor', label: 'Valor', thClass: 'bg-danger', class: 'text-center', thStyle: { width: '20%' }, sortable: true,
        },
      ],
      fieldsPais: [
        {
          key: 'id', label: 'Id', thClass: 'th-custom-color', thStyle: { width: '22%' },
        },
        {
          key: 'descripcion', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '78%' }, sortable: true,
        },
      ],
      fieldsGenero: [
        {
          key: 'id', label: 'Id', thClass: 'th-custom-color', thStyle: { width: '20%' },
        },
        {
          key: 'nombre', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '40%' },
        },
        {
          key: 'sigla', label: 'Sigla', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '40%' },
        },
      ],
      listaPaises: [],
      listaGeneros: [],
      fieldsPacientes: [
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'nombre', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'apellido', label: 'Apellido', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'mail', label: 'Email', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'fecha_de_nacimiento', label: 'Fecha de Nacimiento', thClass: 'th-custom-color', class: 'text-center pl-0', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'nacionalidad', label: 'Nacionalidad', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '13%' }, sortable: true,
        },
        {
          key: 'sexo', label: 'Sexo', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'celular', label: 'Celular', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'carnet_de_identidad', label: 'Carnet de Identidad', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '14%' }, sortable: true,
        },
      ],
      pacientes: [],
      tablePacientes: [],
      perPagePacientes: 100,
      perPageValidaciones: 100,
      currentPagePacientes: 1,
      currentPageValidacion: 1,
      listaValidaciones: [],
      tableValidaciones: [],
    };
  },
  created() {
    this.cargarReferencias();
  },
  methods: {
    async cargarReferencias() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get('/clinic/patient/load_massive/load_reference');
        this.listaPaises = RESPONSE.data.data.paises;
        this.listaGeneros = RESPONSE.data.data.sexos;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    handleFileUpload() {
      this.existeArchivo = true;
      const [files] = this.$refs.file.files;
      this.file = files;
    },
    async downloadPlantillaNewDatos() {
      try {
        const RESPONSE = await axios.get('/clinic/patient/load_massive/template_format', {
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'data:application/vnd.ms-excel' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        const A = document.createElement('a');
        A.href = DOWNLOAD_URL;
        A.download = 'Formato para Importar Nuevos Pacientes.xlsx';
        document.body.appendChild(A);
        A.click();
        document.body.removeChild(A);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async subirArchivo() {
      this.isUploading = true;
      this.currentPagePacientes = 1;
      this.currentPageValidacion = 1;
      try {
        const DATA = new FormData();
        DATA.append('file', this.file);
        const RESPONSE = await axios.post('/clinic/patient/load_massive/upload_import', DATA);
        this.pacientes = RESPONSE.data.data.pacientes;
        const FIN = this.currentPagePacientes * this.perPagePacientes;
        this.tablePacientes = this.pacientes.slice(0, FIN);
        this.listaValidaciones = [];
        this.tableValidaciones = [];
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        this.listaValidaciones = [];
        this.tableValidaciones = [];
        this.pacientes = [];
        this.tablePacientes = [];
        if (error.message === 'Network Error') {
          util.showNotify('Error al subir un archivo modificado, refresque la pagina e intente nuevamente.', 'error');
          return;
        }
        if (error.response.data.error === 422) {
          util.showNotify(error.response.data.data.file[0], 'error');
          return;
        }
        if (error.response.data.error === 2001) {
          util.showNotify(error.response.data.message, 'error');
          return;
        }
        this.listaValidaciones = error.response.data.data;
        const FIN = this.currentPageValidacion * this.perPageValidaciones;
        this.tableValidaciones = this.listaValidaciones.slice(0, FIN);
      } finally {
        this.isUploading = false;
      }
    },
    async guardarListaConfirmada() {
      this.isUploading = true;
      try {
        const REQUEST = {
          pacientes: this.pacientes,
        };
        const RESPONSE = await axios.post('/clinic/patient/load_massive/store_import', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.redirect({ name: 'paciente-index' });
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isUploading = false;
      }
    },
    redirect(path) {
      this.$router.push(path);
    },
    listarTablaPacientes() {
      const INICIO = (this.currentPagePacientes - 1) * this.perPagePacientes;
      const FIN = this.currentPagePacientes * this.perPagePacientes;
      this.tablePacientes = this.pacientes.slice(INICIO, FIN);
    },
    listarTablaValidacion() {
      const INICIO = (this.currentPageValidacion - 1) * this.perPageValidaciones;
      const FIN = this.currentPageValidacion * this.perPageValidaciones;
      this.tableValidaciones = this.listaValidaciones.slice(INICIO, FIN);
    },
    eliminarItem(index) {
      const REFERENCIA = (this.currentPagePacientes - 1) * this.perPagePacientes;
      this.tablePacientes.splice(index, 1);
      this.pacientes.splice(index + REFERENCIA, 1);
    },
  },
};
</script>

<style scoped>
.table-scroll
{
  height: 400px;
  overflow-y: scroll;
}
</style>
